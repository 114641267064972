<template>
  <div>
    <!--begin::Dashboard-->
    <div class="p-5" style="background: #f5f8fa">
      <b-container class="bv-example-row">
        <b-row align-h="between">
          <b-col cols="3" style="display: flex; align-items: center"
            ><h3><b> Referrals and Retention</b></h3></b-col
          >
          <b-col
            cols="8"
            style="display: flex; justify-content: flex-end; margin-right: 5vw"
            ><div>
              <date-range-picker
                ref="picker"
                open="left" 
                v-model="dates"
                :disabled="isdisable"
                :ranges="false"
                ><div slot="footer" slot-scope="data" class="slot">
                  <div class="float-right mb-5">
                    <span v-if="data.rangeText != ' - '">
                      {{ data.rangeText }}
                    </span>
                    <span class="m-4">
                      <a
                        @click="clearRange"
                        v-if="!data.in_selection"
                        class="btn btn-success btn-sm"
                        >Clear</a
                      >
                    </span>
                    <span>
                      <a
                        @click="data.clickCancel"
                        v-if="!data.in_selection"
                        class="btn btn-success btn-sm"
                        >Cancel</a
                      >
                    </span>
                    <span class="m-4">
                      <a
                        @click="data.clickApply"
                        v-if="!data.in_selection"
                        class="btn btn-success btn-sm"
                        >Apply</a
                      >
                    </span>
                  </div>
                </div>
              </date-range-picker>
              <b-dropdown
                style="float: right; padding: 0px !important"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                right
              >
                <template #button-content>
                  <b-icon @click="closeDateRange()" icon="calendar"></b-icon>
                </template>
                <b-dropdown-item @click="dateSelected(1)"
                  >Today</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(2)"
                  >Yesterday</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(3)"
                  >Last 7 Days</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(4)"
                  >Last 30 Days</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(5)"
                  >This month</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(6)"
                  >Last Month</b-dropdown-item
                >
                <b-dropdown-item @click="dateSelected(7)"
                  >Custom Range</b-dropdown-item
                >
              </b-dropdown>
            </div></b-col
          >
        </b-row>
      </b-container>

      <b-container class="bv-example-row mt-4">
        <b-row>
          <b-col
            ><b-card
              ><div>
                <div
                  class="mb-5"
                  style="display: flex; justify-content: space-between"
                >
                  <div>
                    <b-button size="sm" @click="RnRReport"
                      >View Report</b-button
                    >
                  </div>
                </div>
                <br />
                <b-table
                  id="rmy-table"
                  hover
                  :fields="Rfields"
                  :per-page="rperPage"
                  :current-page="rcurrentPage"
                  :items="items1"
                >
                  <template #cell(lastName)="row">
                    {{ name(row.item) }}
                  </template>
                  <template #cell(terminationDate)="row">
                    {{ dobformat2(row.item.terminationDate) }}
                  </template>
                  <template #cell(joinDate)="row">
                    {{ dobformat2(row.item.joinDate) }}
                  </template>
                </b-table>
                <b-pagination
                  v-model="rcurrentPage"
                  :total-rows="rrows"
                  :per-page="rperPage"
                  aria-controls="rmy-table"
                ></b-pagination>
              </div> </b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import { mapGetters } from "vuex";
export default {
  name: "dashboard",
  components: {
    DateRangePicker,
  },
  watch: {
    dates: {
      handler: function () {
        this.fetchData();
      },
    },
  },
  data() {
    return {
      isdisable: true,
      tableData: [],
      perPage: 10,
      currentPage: 1,
      rperPage: 10,
      rcurrentPage: 1,
      dates: {
        startDate: null,
        endDate: null,
      },

      Rfields: [
        {
          key: "lastName",
          label: "Client Name",
        },
        {
          key: "subCode",
          label: "Service",
        },
        {
          key: "authorizationNumber",
          label: "Authorization Number",
        },
        {
          key: "joinDate",
          label: "Start Of Care",
        },
        {
          key: "terminationDate",
          label: "Termination Date",
        },
      ],

      items1: [
        { payor: 40, New: "Dickerson", Term: "Macdonald" },
        { payor: 21, New: "Larsen", Term: "Shaw" },
      ],
    };
  },
  mounted() {
    this.dates = {
      startDate: new Date().setDate(new Date().getDate()),
      endDate: new Date().setDate(new Date().getDate()),
    };
    this.fetchData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  computed: {
    ...mapGetters(["Getrefferal"]),
    rows() {
      return this.tableData.length;
    },
    rrows() {
      return this.items1.length;
    },

    computedDate() {
      return new Date(this.form.date).setDate(
        new Date(this.form.date).getDate() - 1
      );
    },
  },
  methods: {
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    name(x) {
      return x.lastName + " " + x.firstName;
    },
    floatfixed(obj) {
      return parseFloat(obj).toFixed(2);
    },

    RnRReport() {
      const doc = new jsPDF();
      var data = [];

      this.items1.forEach((t) => {
        var row = [];
        row.push(t.lastName);
        row.push(t.firstName);
        row.push(t.subCode);
        row.push(t.authorizationNumber);
        row.push(t.joinDate);
        row.push(t.terminationDate);
        data.push(row);
      });

      // Or use javascript directly:
      doc.autoTable({
        head: [
          [
            "Last Name",
            "First Name",
            "Service",
            "Authorization Number",
            "Join Date",
            "Termination Date",
          ],
        ],
        body: data,
      });

      doc.save("Referrals_and_Retention.pdf");
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "application/pdf" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    dateSelected(obj) {
      if (obj == 1) {
        this.dates = {
          startDate: new Date(),
          endDate: new Date(),
        };
      }
      if (obj == 2) {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        this.dates = {
          startDate: date,
          endDate: date,
        };
      }
      if (obj == 3) {
        var sdate = new Date();
        sdate.setDate(sdate.getDate() - 7);
        this.dates = {
          startDate: sdate,
          endDate: new Date(),
        };
      }
      if (obj == 4) {
        
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        this.dates = {
          startDate: priorDate,
          endDate: today,
        };
      }
      if (obj == 5) {
        var date = new Date();
        this.dates = {
          startDate: new Date(date.getFullYear(), date.getMonth(), 1),
          endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        };
      }
      if (obj == 6) {
        var date = new Date();

        this.dates = {
          startDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
          endDate: new Date(date.getFullYear(), date.getMonth(), 0),
        };
      }
      if (obj == 7) {
        this.isdisable = false;
      }
    },
    dobformat(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear() +
          " " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        );
      }
    },
    async fetchData() {
      var startDate;
      var endDate;
      if (this.dates.startDate) {
        startDate = this.dobformat(this.dates.startDate);
      }
      if (this.dates.endDate) {
        endDate = this.dobformat(this.dates.endDate);
      }
      await this.$store
        .dispatch("getRefferalDetailSummary", {
          startdate: startDate,
          enddate: endDate,
        })
        .then((r) => {
          
          this.items1 = r;
        });
    },
    clearRange() {
      this.dates = {
        startDate: null,
        endDate: null,
      };
    },
    showDateRange() {
      this.datePicker = true;
    },

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>

<style scoped>
.card-line_1 {
  display: flex !important;
  align-items: center;
}
.ratio_bg {
  border-radius: 7px;
  background-color: #ec3a6b;
  margin: 0% 6%;
  padding: 1% 4%;
}
.ratio_bg1 {
  background-color: #5dcd8d !important;
}
.dropdown-item:hover {
  color: blue !important;
}
</style>